<template>
  <div>
    <div ref="pointCursor"></div>
    <div class="control">
      <v-autocomplete
        ref="autocomplete"
        v-model="employee"
        :items="items"
        hide-selected
        hide-no-data
        color="lighten-2"
        item-title="text"
        item-value="value"
        :label="label"
        v-model:search="employeeSearch"
        :rules="[rules]"
        variant="underlined"
        required
        multiple
        :autofocus="autofocus"
      >
        <template v-slot:chip="{ props, item }">
          <div class="permissions-chip">
            <v-chip
              :close="false"
              v-bind="props"
              :class="item.value.itemClass"
            >
            {{ item.value.name }}
            <v-icon @click.stop="remove(item.value)" end icon="mdi-close-circle"></v-icon>
            </v-chip>
            <div class="permissions-card" @click.stop="resetCursor()">
              <v-card max-width="400">
                <v-img :src="item.value.facebook_photo_url"></v-img>
                <v-card-title>{{ item.value.name }}</v-card-title>
                <v-card-text>
                  <p>
                    <span>Agency:</span><b>{{ item.value.agency }}</b>
                  </p>
                  <p>
                    <span>Title:</span><b>{{ item.value.title }}</b>
                  </p>
                  <router-link
                    :to="{ name: 'Employee', params: { uid: item.value.id } }"
                    target="_blank"
                  >
                    MORE
                  </router-link>
                </v-card-text>
              </v-card>
            </div>
          </div>
        </template>
      </v-autocomplete>
    </div>
  </div>
  <deleteDialog v-model:value="dialogDelete" @agree="deleteEmployee" />
</template>
<script>
import deleteDialog from "@/components/common/deleteDialog";
export default {
  data() {
    return {
      employeeSearch: "",
      employee: [],
      items: [],
      cancelSource: null,
      baseURL: "/api/v1/employees/autocomplete?noloading=true&",
      temp: [],
      dialogDelete: false,
      lastAction: "add",
      lastOperateItem: {},
      showOppPermission: false,
      showPermissionDialog: false,
      lastOperateItemList: [],
      deleteItem: null,
    };
  },
  components: {
    deleteDialog,
  },
  props: {
    selectedEmployee: Array,
    title: null,
    rules: null,
    placeHolder: null,
    params: null,
    userType: null,
    label: null,
    displayType: null,
    needShowOppPermission: null,
    ignoreUids: Array,
    oppPermissionType: null,
    dialogStatus: Boolean,
    autofocus: { type: Boolean, default: false },
    showRemoveDialog: Boolean,
    noNeedEmit: { type: Boolean, default: false },
    optionsParams: null
  },
  computed: {
    placeholder: function () {
      return this.placeHolder ? this.placeHolder : "Please input name or email";
    },
    url: function () {
      return this.params
        ? this.baseURL + this.params.join("&") + "&"
        : this.baseURL;
    },
    showText: function () {
      return this.displayType || "name";
    },
  },
  created() {
    if (this.selectedEmployee && this.selectedEmployee.length > 0) {
      this.items = this.selectedEmployee;
      this.selectedEmployee.forEach((item) => {
        this.employee.push(item.value);
      });
    }
  },
  watch: {
    selectedEmployee: {
      handler: function (val) {
        if (val.length > 0) {
          this.items = this.selectedEmployee;
          this.employee = this.selectedEmployee.map((item) => item.value);
        }
      },
      deep: true,
    },
    employeeSearch: function (val) {
      if (!val || val.length < 3) {
        this.refreshItems(this.employee);
        return;
      }
      this.cancelRequest();
      this.cancelSource = this.$http.CancelToken.source();
      let url = this.url;
      if (this.needShowOppPermission) {
        if (this.oppPermissionType == "Reporter") {
          url = url + "type=reporter&";
        }
        if (this.oppPermissionType == "Finance") {
          url = url + "type=finance&";
        }
        if (this.oppPermissionType == "Admin") {
          url = url + "type=admin&";
        }
        url = url + "&org_id=" + this.userType + "&";
      }
      this.$http
        .get(url + "keywords=" + val, {
          cancelToken: this.cancelSource.token,
        })
        .then((response) => {
          if (response) {
            this.items = response.data;
            if(this.ignoreUids){
              this.items = this.items.filter(item=>!this.ignoreUids.some(it=>it == item.value.uid));
            }
            this.employee.forEach((result) => {
              if (!this.items.some((item) => item.value.uid === result.uid)) {
                this.items.push({
                  text: result.name + "(" + result.email + ")",
                  value: result,
                });
              }
            });
          }
        });
    },
    employee: {
      handler: function (val) {
        if (JSON.stringify(val) != JSON.stringify(this.temp)) {
          if (val.length > this.temp.length) {
            this.lastAction = "add";
            this.lastOperateItem = val[val.length - 1];
            this.lastOperateItemList = val.filter(
              (item) => !this.temp.some((it) => it.uid == item.uid)
            );
          } else {
            this.lastAction = "remove";
            const lastOperate = this.temp.filter(
              (item) => !val.some((it) => JSON.stringify(it) == JSON.stringify(item))
            );
            this.lastOperateItem = lastOperate[0];
            this.refreshItems(val);
          }
          this.employeeSearch = "";
          this.temp = Object.assign([], val);
          if (!this.noNeedEmit || this.deleteItem != null){
            this.$emit("setValue", {
              type: this.userType,
              val: val,
              items: this.items,
              lastOperateItem: this.lastOperateItem,
              lastAction: this.lastAction,
              lastOperateItemList: this.lastOperateItemList,
              optionsParams: this.optionsParams
            });
          }
        }
      },
      deep: true,
      immediate: true,
    },
    oppPermissionType: function () {
      this.employee = [];
      this.items = [];
      setTimeout(() => {
        this.$refs.autocomplete.focus();
      }, "100");
    },
  },
  methods: {
    cancelRequest() {
      if (this.cancelSource) {
        this.cancelSource.cancel("cancel request");
      }
    },
    deleteEmployee() {
      this.removeItem(this.deleteItem);
      this.dialogDelete = false;
    },
    remove(item) {
      this.deleteItem = item;
      if (this.showRemoveDialog) {
        this.dialogDelete = true;
      } else {
        this.removeItem(item);
      }
    },
    removeItem(item) {
      this.lastOperateItem = item;
      let index = -1;
      this.employee.forEach((result, i) => {
        if (JSON.stringify(result) == JSON.stringify(item)) {
          index = i;
        }
      });
      if (index >= 0) this.employee.splice(index, 1);
    },
    refreshItems(employees) {
      this.items = [];
      employees.forEach((result) => {
        this.items.push({
          text: result.name + "(" + result.email + ")",
          value: result,
        });
      });
    },
    updatePermission(data) {
      data.employee.forEach((result) => {
        this.employee.push(result);
      });
      data.items.forEach((result) => {
        this.items.push(result);
      });
    },
    resetCursor() {
      this.$refs.pointCursor.click();
    },
    clearAutocomplete() {
      this.items = [];
      this.employee = [];
    },
  },
  emits: ["setValue"],
};
</script>

<style lang="scss" scoped>
:deep(.opp_reporter) {
  background: #818386 !important;
  color: #fff !important;
  border-radius: 10px;

  .v-chip__close {
    color: #fff !important;
  }

  button {
    color: #fff !important;
    margin-top: -3px;

    &:hover {
      opacity: 1 !important;
    }
  }
}

:deep(.opp_admin) {
  background: #58cff4 !important;
  color: #fff !important;
  border-radius: 10px !important;

  .v-chip__close {
    color: #fff !important;
  }

  button {
    color: #fff !important;
    margin-top: -3px;

    &:hover {
      opacity: 1 !important;
    }
  }
}

:deep(.opp_finance) {
  background: #22a44e !important;
  color: #ffffff !important;
  border-radius: 10px;

  .v-chip__close {
    color: #fff !important;
  }

  button {
    color: #fff;
    margin-top: -3px;

    &:hover {
      opacity: 1 !important;
    }
  }
}

:deep(.v-chip--removable) {
  button {
    margin-top: -3px;

    &:hover {
      opacity: 1 !important;
    }
  }
}

.v-list-item__content {
  padding: 12px 0 !important;
}

.v-chip__content {
  button {
    margin-top: -4px;
  }
}

.permission_block {
  .permissions-chip {
    position: relative;

    &:hover .permissions-card {
      display: block;
    }
  }
}

.permission_box {
  .permissions-chip {
    position: relative;

    span.v-chip {
      background: #e0e0e0;
      height: 32px;
      display: flex;
      padding: 0 10px;

      .v-chip__content {
        padding: 0 !important;
        i.mdi-close-circle {
          line-height: 22px;
        }
      }
    }

    &:hover .permissions-card {
      display: block;
    }
  }
}

.permissions-card {
  padding-bottom: 10px;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  display: none;
  z-index: 99;

  .v-card {
    padding: 20px 14px;
    min-width: 240px;

    .v-img {
      width: 60px;
      height: 60px;
      border-radius: 50% !important;
      margin: 0 auto 10px;
    }

    .v-card-title {
      text-align: center;
      display: block;
      font-size: 16px;
      background: transparent;
      padding: 0;
    }

    .v-card-text {
      text-align: center;
      padding: 0 10px;

      p {
        font-size: 14px;
        line-height: 24px;
        text-align: left;
        display: flex;
        align-items: top;

        span {
          padding-right: 6px;
          font-size: 15px;
          line-height: 22px;
          color: #000;
          width: 65px;
          text-align: right;
        }

        b {
          width: 68%;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-align: start;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          text-overflow: ellipsis;
          font-weight: 300;
        }
      }

      a {
        margin-top: 10px;
        display: inline-block;
        font-family: $TBWAGrotesk_Bold;
      }
    }
  }
}
</style>
