<template>
  <div class="content-form float-right form_content">
    <section class="nomination_form form">
      <div class="form-body form-body-padding">
        <v-form ref="form" :lazy-validation="lazy">
          <div class="add_field" v-if="dashboardAddNominee">
            <div class="field">
              <span class="inline-top label_title"
                ><span class="required_start"> *</span>Program</span
              >
              <div class="v-row">
                <div class="inline-top control v-col-8">
                  <v-select
                    v-model="programSelect"
                    :items="programs"
                    class="inline-top control"
                    item-title="name"
                    item-value="id"
                    :rules="[(v) => !!v || 'this field is required']"
                    @update:modelValue="changeProgram"
                  ></v-select>
                </div>
                <div class="inline-top control v-col-4 pl-4">
                  <v-select
                    v-model="season"
                    :items="seasons"
                    class="inline-top control"
                    item-title="name"
                    item-value="id"
                    :rules="[(v) => !!v || 'this field is required']"
                  ></v-select>
                </div>
              </div>
            </div>
          </div>
          <div class="public_field">
            <div class="field">
              <span class="inline-top label_title"
                ><span class="required_start"> *</span>Nominee</span
              >
              <div class="inline-top control">
                <nomineeSearch
                  :userType="'nominee'"
                  :params="['nominee=true']"
                  :placeHolder="'Nominee name'"
                  @setValue="updateNominee"
                  :rules="nomineeRequired"
                  v-if="!is_edit"
                />
                <div v-if="is_edit" class="nominee-info">
                  <v-avatar start size="50" class="float-left avatar">
                    <div v-if="form.avatar" style="width: 50px; height: 50px">
                      <v-img :src="form.avatar"></v-img>
                    </div>
                    <div v-else class="inline-middle talent_bg icon-user"></div>
                  </v-avatar>
                  <div class="float-left text ml-5">
                    <h2>{{ form.full_name }}</h2>
                    <h4>({{ form.email }})</h4>
                  </div>
                </div>
                <a
                  v-if="form.nominee_uid"
                  :href="profile_url"
                  target="_blank"
                  class="nomination_form_link"
                >
                  <v-icon size="small" color="#1976d2">mdi-link-variant</v-icon>
                  Pirate Profile</a
                >
              </div>
            </div>
            <div class="field">
              <span class="inline-top label_title"
                ><span class="required_start"> *</span>Market</span
              >
              <div class="inline-top control" v-if="!is_show_select">
                <v-text-field
                  v-model="items['market']"
                  placeholder="Market Name"
                  variant="outlined"
                  density="compact"
                  class="v-text-filed-compact"
                  disabled
                  required
                ></v-text-field>
              </div>
              <div v-else class="inline-top control">
                <v-select
                  v-model="market_uuid"
                  :items="market_names"
                  class="inline-top control"
                  item-title="market"
                  item-value="market_uuid"
                  :rules="[(v) => !!v || 'market required']"
                ></v-select>
              </div>
            </div>
            <div class="field">
              <span class="inline-top label_title"
                ><span class="required_start"> *</span>Agency</span
              >
              <div class="inline-top control">
                <v-text-field
                  v-model="form['agency']"
                  placeholder="Agency Name"
                  variant="outlined"
                  density="compact"
                  class="v-text-filed-compact"
                  disabled
                  required
                ></v-text-field>
              </div>
            </div>
            <div class="field">
              <span class="inline-top label_title"
                ><span class="required_start"> *</span>Job Title</span
              >
              <div class="inline-top control">
                <v-text-field
                  v-model="form['jobTitle']"
                  placeholder="Job Title"
                  variant="outlined"
                  density="compact"
                  class="v-text-filed-compact"
                  required
                  :rules="[(v) => !!v || 'Job Title required']"
                  :disabled="is_edit"
                ></v-text-field>
              </div>
            </div>
            <div class="field">
              <span class="inline-top label_title"
                ><span class="required_start"> *</span>Does the nominee manage
                others?</span
              >
              <div class="inline-top control">
                <v-btn-toggle v-model="form['managerStatus']" mandatory>
                  <v-btn
                    size="x-small"
                    value="true"
                    class="px-5"
                    color="#fff3c4"
                    >Yes</v-btn
                  >
                  <v-btn
                    size="x-small"
                    value="false"
                    class="px-5"
                    color="#fff3c4"
                    >No</v-btn
                  >
                </v-btn-toggle>
              </div>
            </div>
            <div class="field">
              <span class="inline-top label_title"
                ><span class="required_start"> *</span>Nominee’s Manager</span
              >
              <div class="inline-top control" v-if="is_edit">
                <v-text-field
                  disabled
                  :model-value="form['manager']"
                ></v-text-field>
              </div>
              <div class="inline-top control" v-else>
                <nomineeSearch
                  :userType="'manager'"
                  :selectedEmployee="form['reporting_manager']"
                  :placeHolder="'Manager name or email'"
                  @setValue="updateManager"
                  :rules="managerRequired"
                />
              </div>
            </div>
            <div class="field">
              <span class="inline-top label_title"
                ><span class="required_start"> *</span>Why are you nominating
                this person?</span
              >
              <div class="inline-top control">
                <v-textarea
                  :model-value="value"
                  v-model="form['reason']"
                  variant="outlined"
                  :rules="[
                    (v) =>
                      (v && v.length >= 20) ||
                      (!v ? '0/20 characters' : v.length + '/20 characters'),
                  ]"
                  required
                  rows="5"
                  row-height="30"
                ></v-textarea>
                <div class="clear"></div>
              </div>
            </div>
          </div>
        </v-form>
      </div>
    </section>
    <section class="nomination_form footer">
      <div class="nomination_form_area">
        <ul class="controls">
          <li>
            <v-btn
              :disabled="canSubmit"
              @click="submitNomination()"
              class="btn square"
            >
              Submit
            </v-btn>
          </li>
          <li><v-btn @click="cancel" class="btn flat">Cancel</v-btn></li>
        </ul>
        <div class="clear"></div>
      </div>
    </section>
  </div>
</template>
<style lang="scss">
@import "~@/assets/styles/app/nominations.scss";
</style>
<script>
import nomineeSearch from "@/components/common/employeeSearch";
export default {
  components: { nomineeSearch },
  data() {
    return {
      valid: true,
      items: { programs: [] },
      form: { reason: null, managerStatus: "true" },
      value: "Why!",
      lazy: false,
      whatever: [],
      hasJobTitle: false,
      market_names: [],
      market_uuid: null,
      is_show_select: false,
      programSelect: null,
      nominee: null,
      season: "current",
      seasons: [
        { name: "Current Season", id: "current" },
        { name: "Alternatives", id: "alternative" },
        { name: "Next Season", id: "next" },
      ],
      brands: [],
      options: [],
      projects: [],
      project_options: [],
      inputValue: null,
      inputBrandValue: null,
    };
  },
  async created() {
    this.programSelect = this.program;
    this.$root.$el.querySelector("#main").scrollTo({ top: 0, left: 0 });
    if (this.$route.query.uuid) {
      this.$http
        .get("/api/v1/nominations/" + this.$route.query.uuid)
        .then((response) => {
          this.form = response.data.form;
          for (let i in response.data.items) {
            this.items[i] = response.data.items[i];
          }
          this.form["managerStatus"] = response.data.form["managerStatus"];
        });
    } else {
      this.$http
        .get("/api/v1/nominations/new?id=" + this.programId)
        .then((response) => {
          this.is_show_select = response.data["is_show_select"];
          if (response.data["is_show_select"]) {
            this.market_names = response.data["result"];
          } else {
            this.items["market"] = response.data["market"];
            this.form["market_uuid"] = response.data["market_uuid"];
          }
        });
    }
  },
  props: {
    program: null,
    dashboardAddNominee: Boolean,
    programs: null,
  },
  computed: {
    is_edit() {
      return this.$route.query.uuid ? true : false;
    },
    nomineeRequired() {
      return (
        !!(this.form.nominee && this.form.nominee_uid) || "Nominee is required"
      );
    },
    managerRequired() {
      return (
        !!(this.form.manager && this.form.manager.uid) || "Manager is required"
      );
    },
    profile_url() {
      return "/employee/show/" + this.form.nominee_id;
    },
    programId() {
      return this.dashboardAddNominee
        ? this.programSelect
        : this.$route.params.id;
    },
    category() {
      return this.dashboardAddNominee
        ? this.season
        : this.$route.query.category;
    },
    canSubmit() {
      if (this.is_edit) {
        return !(this.form.reason != null && this.form.reason.length >= 20);
      } else if (this.items["market"]) {
        return !(
          this.form.nominee != undefined &&
          this.form.jobTitle != undefined &&
          this.form.manager != undefined &&
          this.form.reason != null &&
          this.form.reason.length >= 20
        );
      } else {
        return !(
          this.form.nominee != undefined &&
          this.form.jobTitle != undefined &&
          this.form.manager != undefined &&
          this.market_uuid != null &&
          this.form.reason != null &&
          this.form.reason.length >= 20
        );
      }
    },
  },
  methods: {
    submitNomination() {
      const uuid = this.$route.query.uuid;
      let url = "/api/v1/nominations";
      let method = "post";
      this.form["brands"] = this.brands.map((tag) => tag.text);
      if (!uuid) {
        if (this.is_show_select) {
          this.form["market_uuid"] = this.market_uuid;
        }
      } else {
        url = "/api/v1/nominations/" + uuid;
        this.form.uuid = uuid;
        method = "put";
      }
      this.form["program_id"] = this.programId;
      this.form["category"] = this.category;
      this.$http({
        method: method,
        url: url,
        data: { form: this.form },
      }).then((response) => {
        if (response.data.status == "success") {
          if (this.dashboardAddNominee) {
            this.$emit("closeForm");
            if (this.programSelect == this.program) {
              this.$emit("updateList", {
                user: response.data.data,
                category: this.seasons.filter((i) => {
                  return i.id == this.category;
                })[0].name,
              });
            }
          } else {
            this.goToList();
          }
        }
      });
    },
    async updateNominee(data) {
      if (data.val) {
        this.nominee = data.val.nominee_id;
        await this.checkNominee().then((res) => {
          if (res.data != "error") {
            this.form["nominee"] = data.val;
            for (let i in data.val) {
              this.form[i] = data.val[i];
            }
          }
        });
      }
    },
    async checkNominee() {
      let params = {};
      if (this.dashboardAddNominee) {
        params = {
          nominee_id: this.nominee,
          program_id: this.programSelect,
          market_id: this.market_uuid,
        };
      } else {
        params = {
          nominee_id: this.nominee,
          program_id: this.programSelect.id,
          market_id: this.form["market_uuid"],
        };
      }
      let data = null;
      await this.$http
        .get("/api/v1/nominations/check_nominee?noloading=true", {
          params: params,
        })
        .then((res) => {
          data = res.data;
        });
      return Promise.resolve(data);
    },
    goToList() {
      this.$root.$el.querySelector("#main").scrollTo({ top: 0, left: 0 });
      this.$router.push({ name: "ProgramNominationList" });
    },
    updateManager(data) {
      this.form["manager"] = data.val;
    },
    checkboxValue(attr) {
      return !this.form[attr] || !this.form[attr].length;
    },
    async changeProgram() {
      await this.checkNominee().then((res) => {
        if (res.data != "error") {
          this.$http
            .get("/api/v1/programs/get_markets", {
              params: { uuid: this.programId },
            })
            .then((response) => {
              this.market_names = response.data;
              this.market_uuid = null;
            });
        }
      });
    },
    cancel() {
      if (this.dashboardAddNominee) {
        this.$emit("closeForm");
      } else {
        this.goToList();
      }
    },
  },
};
</script>
