<template>
  <div class="pa-0 ag-employees">
    <div class="pa-5 table_search">
      <h1 class="mt-2 mb-5">All Staff</h1>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-inner-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          variant="underlined"
        ></v-text-field>
      </v-card-title>
    </div>
    <v-data-table
      :headers="headers"
      :items="data"
      :search="search"
      fixed-header
    >
      <template v-slot:item="{ item }">
        <tr>
          <td>
            <div class="avater_64">
              <div v-if="item.selectable.facebook_photo_url">
                <img :src="item.selectable.facebook_photo_url + '&width=64'" />
              </div>
              <div v-else class="user_box">
                <i class="icon-user"></i>
              </div>
            </div>
          </td>
          <td><router-link :to="{ name: 'Employee', params: { uid: item.selectable.id }}">{{ item.selectable.full_name }}</router-link></td>
          <td>{{ item.selectable.title }}</td>
          <td>{{ item.selectable.department }}</td>
          <td>{{ item.selectable.email }}</td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: [],
      search: '',
      headers: [
        { title: "Avatar", sortable: false, class:'text-left pl-7', key: 'facebook_photo_url' },
        { title: "Name", key: "full_name",class:'text-left' },
        { title: "Title", key: "title",class:'text-left',},
        { title: "Department", key: "department",class:'text-left',},
        { title: "Email", key: "email",class:'text-left'},
      ],
    };
  },
  async created() {
    const location_code = this.$route.params.location_code;
    this.$http
      .get(
        "/api/v1/agencies/show_tab?tab_name=employees&location_code=" +
          location_code +
          ""
      )
      .then((response) => {
        this.data = response.data.users;
      });
  },
};
</script>
