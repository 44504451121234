<template>
  <v-menu
    transition="scale-transition"
    location="bottom"
  >
    <template v-slot:activator="{ props }">
      <div class="button_1">
      <v-btn
        class="mt-3"
        max-height="0"
        max-width="10"
        variant="text"
        v-bind="props"
      >
        <div class="talent_bg new_talent_bg" v-if="permissions.facebook_avatar">
          <img :src="permissions.facebook_avatar+ '&width=200'"/>
        </div>
        <div v-else class="talent_bg1"><v-icon>icon-user</v-icon></div>
      </v-btn>
    </div>
    </template>
    <v-list>
      <v-list-item>
        <router-link class="font_color" :to="{ name: 'EmployeeProfile'}">{{ fullName }}</router-link>
      </v-list-item>
      <v-list-item>
        <router-link class="font_color" :to="{ name: 'Agency', params: { location_code: locationCode }}">My Agency</router-link>
      </v-list-item>
      <v-list-item>
        <a href="#" @click="logout" class="font_color">Sign Out</a>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<style lang="scss" scoped>
  .font_color{
    color: black;
  }
  .button_1 {
    margin-top: 32px;
  }
  .talent_bg {
    @include size(40px, 40px);
    @include border-radius(60px);
    line-height: 120px;
    font-size: 90px;
    margin-bottom: 32px;
    text-align: center;
    position: relative;
    overflow: hidden;
    img {
      @extend .vm;
      height: 100%;
    }
  }
  .talent_bg1 {
    margin-bottom: 32px;
  }
  .v-sheet.v-list {
    padding: 0;
  }
  .v-list-item{
    padding: 0;
    padding-inline-end: 0 !important;
    padding-inline-start: 0 !important;
    a{
      padding: 0 20px;
    }
  }
</style>
<script>
import { mapGetters, mapActions } from 'vuex'
import VueJwtDecode from 'vue-jwt-decode'

export default {
  data: function () {
    return {
      current_user: {},
    }
  },
  computed: {
    ...mapGetters('oidcStore', [
      'oidcAccessToken',
      'oidcIdToken',
    ]),
    ...mapGetters(['permissions']),
    fullName: function () {
      return this.given_name + " " + this.family_name
    },
    given_name: function() {
      return this.current_user ? this.current_user.givenName : ''
    },
    family_name: function() {
      return this.current_user ? this.current_user.sn : ''
    },
    uid: function() {
      return this.current_user ? this.current_user.tbwaUid : ''
    },
    locationCode: function() {
      return this.current_user ? this.current_user.company : ''
    },
  },
  async created() {
    await this.refreshCurrentUser()
  },
  watch: {
    '$route': 'refreshCurrentUser'
  },
  methods:{
    ...mapActions('oidcStore', [
      'oidcIsAuthenticated',
    ]),
    async isAuthenticated () {
      this.authenticated = await this.oidcIsAuthenticated()
    },
    async logout () {
      window.location = "/logout";
    },
    async refreshCurrentUser () {
      if(this.oidcAccessToken){
        this.$store.dispatch("permissions");
        this.current_user = VueJwtDecode.decode(this.oidcAccessToken);
      }
    }
  }
}
</script>
