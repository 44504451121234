<template>
  <div class="capabilities">
    <h1 class="mt-2 mb-5">Capabilities</h1>
    <v-row>
      <div class="v-col-12 v-col-md-3 mb-10">
        <h3 class="mb-5">Lead Creative</h3>
        <template v-if="data.leader">
          <p><a :href="'https://teams.microsoft.com/l/chat/0/0?users='+data.leader.email" class="text-black">{{data.leader.full_name}}</a></p>
          <p>{{data.leader.title}}</p>
          <p>{{data.leader.telephone}}</p>
          <p><a :href="'mailto:'+data.leader.email">{{data.leader.email}}</a></p>
        </template>
      </div>
      <div class="v-col-12 v-col-md-9">
        <h3 class="mb-5">Service Offerings</h3>
        <v-row>
          <p
            class="v-col-12 v-col-sm-6 v-col-md-4"
            v-for="(item, index) in data.service_offerings"
            :key="index"
          >
            {{ item }}
          </p>
        </v-row>
      </div>
    </v-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: {}
    }
  },
  async created(){
    const location_code = this.$route.params.location_code;
    this.$http.get('/api/v1/agencies/show_tab?tab_name=capabilities&location_code=' + location_code + '')
      .then(response => {
        this.data = response.data;
      })
  },
}
</script>
