<template>
  <div class="helpdialog">
      <div class="helpdialog-section">
        <div class="helpdialog-block">
          <h4>Need Help?</h4>
          <ul class="helpdialog-list">
            <li class="helpdialog-item">
              <a :href="teamUrl" target="_blank" class="helpdialog-link">
                <span>New Business Support</span>
                <img :src="require('@/assets/images/team.png')" alt="" />
              </a>
            </li>
            <li class="helpdialog-item">
              <a :href="assistanceUrl" target="_blank" class="helpdialog-link">
                <span>Technical Assistance</span>
                <svg-icon icon-class="arrow-yellow"></svg-icon>
              </a>
            </li>
            <!-- <li class="helpdialog-item">
              <a href="" class="helpdialog-link">
                <span>Submit feedback</span>
                <svg-icon icon-class="arrow-yellow"></svg-icon>
              </a>
            </li> -->
          </ul>
        </div>
        <div class="helpdialog-block">
          <h4>Resources</h4>
          <router-link :to="{ name: 'OpportunityFlow'}" class="helpdialog-resources-link">View User Guide</router-link>
        </div>
      </div>
  </div>
</template>
<script>
export default {
  computed: {
    teamUrl() {
      const baseAuthUrl = "https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize";
      const clientId = process.env.VUE_APP_MS_OAUTH_CLIENT_ID;
      const redirectUri = encodeURIComponent(process.env.VUE_APP_MS_OAUTH_CALLBACK_HELP_URL);
      const scopes = "chat.create chatmember.readwrite chat.readwrite offline_access profile openid";

      const uuidRegex = /\/([^/]+)$/;
      const match = this.$route.name === 'Detail' || this.$route.name === 'OpportunityEdit' ? this.$route.path.match(uuidRegex) : null;
      const stateParam = match ? "&state=" + match[1] : "";

      const url = `${baseAuthUrl}?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}&response_mode=query&scope=${scopes}${stateParam}`;

      return url;
    },
    assistanceUrl(){
      return process.env.VUE_APP_TECHNICAL_ASSISTANCE_URL
    }
  },
}
</script>