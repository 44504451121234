const files = require.context('./show', true, /\.vue$/);
let pages = {};
files.keys().forEach(key => {
  pages[key.replace(/(\.\/|\.vue)/g, '')] = files(key).default;
})

let tabs = [];

Object.keys(pages).forEach(item => {
  tabs.push({
    path: `${item}`,
    name: item,
    component: pages[item]
  })
});
const router = [
  {
    path: '/nominations',
    name: 'NominationList',
    component: () => import('@/components/nominations/index'),
    redirect: '/nominations/main',
    children: [
      {
        path: 'main',
        name: 'Nominationmain',
        component: () => import('./main'),
        children: [
          {
            path: 'program/:id',
            name: 'NominationProgram',
            component: () => import('./show/index'),
            redirect: to => {
              return {
                name: 'ProgramNominationList',
                params: {id: to.params.id}
              }
            },
            children: [
              {
                path: 'list',
                name: 'ProgramNominationList',
                component: () => import('./show/list'),
              },
              {
                path: 'form',
                name: 'NominationForm',
                component: () => import('@/components/nominations/show/form'),
              },
            ]
          },
          {
            path: 'status',
            name: 'NominationStatus',
            component: () => import('./show/status'),
          },
          {
            path: 'dashboard',
            name: 'NominationDashboard',
            component: () => import('./show/dashboard'),
          },
        ],
      },
      {
        path: 'manage_programs',
        name: 'NominationManagePrograms',
        component: () => import('@/components/nominations/programs/index'),
        redirect: { name: 'NominationActivePrograms' },
        children: [
          {
            path: 'active',
            name: 'NominationActivePrograms',
            component: () => import('@/components/nominations/programs/active'),
          },
          {
            path: 'disabled',
            name: 'NominationDisabledPrograms',
            component: () => import('@/components/nominations/programs/disabled'),
          }
        ]
      },
      {
        path: '/nominations/new_program',
        name: 'NominationNewPrograms',
        component: () => import('@/components/nominations/programs/new'),
      },
      {
        path: '/nominations/edit_program/:uuid',
        name: 'NominationEditPrograms',
        component: () => import('@/components/nominations/programs/edit'),
      },
      {
        path: '/nominations/reminders',
        name: 'NominationManageReminders',
        component: () => import('@/components/nominations/reminders/index'),
      },
      {
        path: '/nominations/reminders/new',
        name: 'NominationNewReminder',
        component: () => import('@/components/nominations/reminders/new'),
      },
      {
        path: '/nominations/reminders/:uuid/edit',
        name: 'NominationEditReminder',
        component: () => import('@/components/nominations/reminders/edit'),
      },
      {
        path: '/nominations/intro_4_nominator',
        name: 'NominationIntro4Nominator',
        component: () => import('@/components/nominations/intro_4_nominator'),
      },
      {
        path: '/nominations/intro_4_market_leader',
        name: 'NominationIntro4MarketLeader',
        component: () => import('@/components/nominations/intro_4_market_leader'),
      },
      {
        path: '/nominations/forbidden',
        name: 'NominationForbidden',
        component: () => import('@/components/nominations/forbidden'),
      },
      {
        path: '/nominations/permissions',
        name: 'NominationManageAccess',
        component: () => import('./permission'),
      },
      {
        path: '/nominations/seats',
        name: 'NominationManageSeats',
        component: () => import('./seats/index'),
      },
      {
        path: '/nominations/seats/form',
        name: 'NominationsManageSeatsForm',
        component: () => import('./seats/form'),
      },
      {
        path: '/nominations/alumnis',
        name: 'NominationManageAlumni',
        component: () => import('@/components/nominations/alumnis/index'),
      },
      {
        path: '/nominations/alumnis/new',
        name: 'NominationNewAlumni',
        component: () => import('@/components/nominations/alumnis/new'),
      },
    ],
  },
]

export default router
