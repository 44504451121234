<template>
  <v-snackbar v-model="show" :color="color" :timeout="timeout" location="top" >
    <div v-html="snackText"></div>
    <template v-slot:actions>
      <v-btn variant="text" @click="show = false">
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  created() {
    this.$store.subscribe((action, state) => {
      if (action.type === "SHOW_MESSAGE") {
        this.text = state.snackbar.text;
        this.color = state.snackbar.color;
        this.timeout = state.snackbar.timeout == null ? 5000 : state.snackbar.timeout;
        this.show = true;
      }
    });
  },
  data() {
    return {
      show: false,
      color: "",
      text: "",
      timeout: -1,
    };
  },
  computed: {
    snackText(){
      if (typeof(this.text) == 'object') {
        return this.text.join("<br/>");
      } else {
        return this.text;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
:deep(.bg-error) {
  background-color:#ff5252 !important;
  top: 64px !important;
}
</style>