import * as VueRouter from 'vue-router'
import axios from 'axios'
import store from '../store'
import OidcCallback from '../views/OidcCallback'
import OidcSlientRenew from '../views/OidcSlientRenew'
import Logout from '../views/Logout'
import { vuexOidcCreateRouterMiddleware } from 'vuex-oidc'

import Agencies from '@/components/agencies/router'
import Leaders from '@/components/leaders/router'
import Nominations from '@/components/nominations/router'
import Employees from '@/components/employees/router'
import Opportunities from '@/components/opportunities/router'
import Collections from '@/components/collections/router'
import Resque from '@/components/resque/router'
import Dashboard from '@/components/my_dashboard/router'

import Search from '@/components/search/result'

import err404 from '@/components/public/404'
import err403 from '@/components/public/403'
import err401 from '@/components/public/401'


const routes = [
  ...Agencies,
  ...Leaders,
  ...Nominations,
  ...Employees,
  ...Opportunities,
  ...Collections,
  ...Resque,
  ...Dashboard,
  {
    path: '/',
    name: 'Home',
    component: () => import('@/components/home/index'),
  },
  {
    path: '/oidc-callback', // Needs to match redirectUri (redirect_uri if you use snake case) in you oidcSettings
    name: 'oidcCallback',
    component: OidcCallback
  },
  {
    path: '/oidc-silent-renew',
    name: 'oidcSlientRenew',
    component: OidcSlientRenew
  },
  {
    path: '/search',
    name: "Search",
    component: Search,
  },
  {
    path: '/:catchAll(.*)',
    component: err404,
  },
  {
    path: '/403',
    component: err403,
  },
  {
    path: '/401',
    component: err401,
  },
  {
    path: '/logout',
    component: Logout,
  },
]

const router = VueRouter.createRouter({
  history: VueRouter.createWebHistory(),
  routes,
})


router.beforeEach(vuexOidcCreateRouterMiddleware(store, 'oidcStore'))

router.beforeEach((to) => {
  if (window.location.host.indexOf("opphub") == -1 && to.path.indexOf("opportunities") > -1) {
    window.location.href=`${process.env.VUE_APP_OPPHUB_URL}/opportunity-flow?showPopup=true`;
  }
  checkAppNewVersion()
})

const checkAppNewVersion = async ()=> {
  let local_version = localStorage.getItem("app_version");
  const url = `/version.json?t=${Date.now()}`;
  let res = null;
  try {
    res = await axios.get(url, {
      baseURL: window.location.origin
    });
  } catch (err) {
    // eslint-disable-next-line
    console.error("checkAppNewVersion error: ", err);
  }
  if (!res) return;
  if (local_version) {
    if (local_version != res.data.version) {
      // eslint-disable-next-line
      console.log("Session has expired")
      localStorage.setItem("app_version", res.data.version);
      location.reload();
    }
  } else {
    localStorage.setItem("app_version", res.data.version);
  }
}

export default router
