<template>
  <div class="knowedge">
    <h1 class="mt-2 mb-5">
      <a class="knowledge-btn" :href="knowledgeUrl" target="_blank">Know\edge</a>
    </h1>
    <div class="oppo_title" v-if="data.intelligence.length == 0 && data.knowledge.length == 0">
      <v-icon style="display:inline-flex;color:#1976d2;" >mdi-information</v-icon>
      <span class="ml-2 mt-1" style="font-size:16px; ">There are no available assets.</span>
    </div>
    <div v-else>
      <div v-show="data.intelligence.length > 0">
        <h2 class="mb-5">Latest Intelligence</h2>
        <v-row class="pa-3" :justify="data.intelligence.length % 4 != 0 ? 'start' : 'space-around'">
          <div
            v-for="(item, index) in data.intelligence"
            :key="index"
            class="knowledge"
          >
            <a :href="item.asset_url" target="_blank">
              <template v-if="item.image">
                <div class="knowledge_pic" :style="'background: url(data:' + item.image.type + ';base64,' + item.image.data + ') no-repeat;' " ></div >
              </template>
              <template v-else>
                <div class="no_pic"></div>
              </template>
            </a>
            <div class="inline-middle detail">
              <h4>{{ item.module_name }}</h4>
              <h1>{{ item.title }}</h1>
              <p> {{ formatDate(item.date) }} <span class="dot inline-middle"></span>{{ item.brand }} </p>
              <p>{{ item.agency }}</p>
            </div>
          </div>
        </v-row>
      </div>
      <v-divider v-show="data.intelligence.length > 0 && data.knowledge.length > 0" class="my-10"></v-divider>
      <div v-show="data.knowledge.length > 0">
        <h2 class="mb-5">Latest Creative</h2>
        <v-row class="pa-3" :justify="data.knowledge.length % 4 != 0 ? 'start' : 'space-around'">
          <div
            v-for="(item, index) in data.knowledge"
            :key="index"
            class="knowledge"
          >
            <a :href="item.asset_url" target="_blank" >
              <template v-if="item.image">
                <div class="knowledge_pic" :style=" 'background: url(data:' + item.image.type + ';base64,' + item.image.data + ') no-repeat;' " ></div>
              </template>
              <template v-else>
                <div class="no_pic"></div>
              </template>
            </a>
            <div class="inline-middle detail">
              <h4>{{ item.module_name }}</h4>
              <h1>{{ item.title }}</h1>
              <p>{{ formatDate(item.date) }}<span class="dot inline-middle"></span>{{ item.brand }}</p>
              <p>{{ item.agency }}</p>
            </div>
          </div>
        </v-row>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
export default {
  data() {
    return {
      data: {intelligence: [], knowledge: []}
    }
  },
  async created(){
    const location_code = this.$route.params.location_code;
    this.$http.get('/api/v1/agencies/show_tab?tab_name=knowledge&location_code=' + location_code + '')
      .then(response => {
        this.data = response.data;
      })
  },
  computed:{
    knowledgeUrl(){
      return process.env.VUE_APP_NUXEO_KNOWLEDGE
    },
  },
  methods:{
    formatDate(date) {
      return moment(date).format('MMM D, YYYY');
    }
  }
}
</script>
